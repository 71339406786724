:root {
	--text-3-5xl: 24px;
	--text-3-8xl: 34px;
	--text-4xl: 36px;
}

body {
	font-size: var(--text-base);
}

.nav-link .course-list-count {
	border-radius: var(--border-radius-md);
	padding: 0 0.3rem;
	font-size: var(--text-sm);
	border: 1px solid var(--gray-600);
}

.nav-link.active .course-list-count {
	border: 1px solid var(--primary-color);
}

.page-title {
	font-size: 1.25rem;
	font-weight: 600;
	color: var(--gray-900);
	line-height: 160%;
	letter-spacing: 0.005em;
}

.sticky {
	position: sticky;
	top: -1px;
	z-index: 100;
}

.is-pinned {
	background: #ffffff;
	padding: 0.5rem 0;
	border-bottom: 1px solid var(--gray-300);
}

.field-parent {
	margin-top: 2rem;
}

.field-group .frappe-control .ql-editor:not(.read-mode) {
	background-color: #ffffff;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
	border: 1px solid var(--gray-300);
}

.rating .icon {
	background: var(--gray-200);
	border-radius: var(--border-radius-md);
	padding: var(--padding-xs);
}

.rating .star-click {
	--star-fill: var(--orange-500);
	background: var(--gray-200);
	border-radius: var(--border-radius-md);
	padding: var(--padding-xs);
}

.cta-parent {
	display: flex;
	margin-bottom: 1rem;
}

.all-cta {
	flex: 1;
}

.field-label {
	color: var(--gray-900);
	font-weight: 500;
}

.field-input {
	border: 1px solid var(--gray-300);
	border-radius: var(--border-radius-md);
	padding: 0.5rem;
	width: 100%;
	margin-top: 0.25rem;
}

.field-input:focus-visible {
	outline: none;
}

.field-input .form-control {
	color: initial;
	background-color: inherit;
	padding: 0;
	height: inherit;
	cursor: pointer;
}

.field-group {
	margin-bottom: 1.5rem;
}

.field-description {
	font-size: var(--text-md);
}

.invisible-input {
	border: none;
}

.invisible-input:focus-visible {
	outline: none;
}

.image-preview {
	width: 280px;
	height: 178px;
	border-radius: var(--border-radius-md);
	border: 1px solid var(--gray-300);
	margin-top: 1rem;
}

textarea.field-input {
	height: 300px;
}

.outline-lesson {
	padding: 0.75rem 0;
	border-bottom: 1px solid var(--gray-300);
}

.outline-lesson:last-of-type {
	border-bottom: none;
}

.outline-lesson .level,
.chapter-container .level {
	justify-content: start;
}

.icon-bg {
	background: var(--gray-100);
	padding: 0.5rem;
	border-radius: var(--border-radius-md);
	margin: 0 0.5rem;
}

.quiz-modal {
	min-height: 500px;
}

.ce-block__content {
	max-width: 100%;
	padding: 0 0.5rem;
	margin: 0;
}

.ce-toolbar__content {
	position: unset;
}

.codex-editor--narrow .ce-toolbar__actions {
	left: 100%;
}

.lesson-editor {
	border: 1px solid var(--gray-300);
	border-radius: var(--border-radius-md);
	padding-top: 0.5rem;
}

.lesson-parent .breadcrumb {
	border-bottom: 1px solid var(--gray-300);
	margin-bottom: 2rem;
	padding-bottom: 1rem;
}

.form-width {
	width: 50%;
}

@media (max-width: 768px) {
	.form-width {
		width: 75%;
	}
}

.clickable {
	color: var(--gray-900);
	font-weight: 500;
}

.clickable:hover {
	color: var(--gray-900);
	text-decoration: none;
	cursor: pointer;
}

.question-row .ql-editor.read-mode p:hover {
	cursor: pointer;
}

.question-row .ql-editor.read-mode p {
	display: none;
}

.question-row .ql-editor.read-mode p:first-child {
	display: block;
}

.codex-editor path {
	stroke: var(--gray-800);
}

.drag-handle {
	cursor: move;
}

.edit-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.btn-default:not(:disabled):not(.disabled).active {
	color: white;
	background-color: var(--primary);
	border: none;
}

.field-label.reqd::after {
	content: " *";
	color: var(--red-400);
}

.error-message {
	color: var(--red-500);
	font-size: var(--text-sm);
}

.lessons {
	margin-right: 1.5rem;
}

input[type="checkbox"] {
	-webkit-appearance: auto;
	   -moz-appearance: auto;
	        appearance: auto;
	position: relative;
	width: var(--checkbox-size) !important;
	height: var(--checkbox-size);
	margin-left: var(--checkbox-right-margin) !important;
	background-repeat: no-repeat;
	background-position: center;
	border: 1px solid var(--gray-400);
	box-sizing: border-box;
	box-shadow: 0 1px 2px #0000001a;
	border-radius: 4px;
	-webkit-appearance: none;
	-webkit-print-color-adjust: exact;
}

.course-image {
	height: 168px;
	width: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}

.default-image {
	background-color: var(--avatar-frame-bg);
	color: var(--avatar-frame-color);
	display: flex;
	flex-direction: column;
}

.default-image-text {
	display: flex;
	flex: 1;
	align-self: center;
	justify-content: normal;
	font-size: 7rem;
	font-weight: bold;
}

.course-tags {
	display: flex;
	position: relative;
	top: 1rem;
	right: 1rem;
}

.course-image .course-tags {
	width: -moz-fit-content;
	width: fit-content;
}

.course-card-pills {
	background: #ffffff;
	margin-right: 0;
	margin-left: 0.5rem;
	border-radius: var(--border-radius);
	padding: 3.5px 8px;
	font-size: 11px;
	text-align: center;
	letter-spacing: 0.011em;
	text-transform: uppercase;
	font-weight: 600;
	color: var(--gray-900);
	width: -moz-fit-content;
	width: fit-content;
	border: 1px solid var(--gray-300);
}

.dark-pills {
	background: rgba(25, 39, 52, 0.8);
	color: #ffffff;
}

.dark-pills img {
	width: 0.75rem;
	height: 0.75rem;
}

.common-page-style {
	padding: 1.25rem 0 5rem;
	font-size: var(--text-base);
}

.common-card-style {
	display: flex;
	background: #ffffff;
	border-radius: var(--border-radius-md);
	position: relative;
	border: 1px solid var(--gray-300);
	box-shadow: var(--shadow-inset);
}

.course-card {
	flex-direction: column;
	height: 100%;
	min-height: 350px;
	overflow: auto;
}

.muted-text {
	font-size: 12px;
	line-height: 135%;
}

.course-card-meta {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: var(--text-base);
	color: var(--gray-900);
	margin-bottom: 0.5rem;
}

.icon {
	margin: 0;
}

.lesson-links .icon {
	stroke: none;
}

.onboarding-parent .icon {
	stroke: none;
}

.course-card-content {
	padding: 1rem;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	color: var(--text-color);
}

.course-card-title {
	font-weight: 600;
	color: var(--gray-900);
	font-size: 1.125rem;
}

.short-introduction {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	width: 100%;
	overflow: hidden;
	margin-bottom: 1.25rem;
}

.card-divider {
	border-top: 1px solid var(--gray-300);
	margin-bottom: 1rem;
}

.card-divider-dark {
	border-top: 1px solid #c8cfd5;
	margin-bottom: 16px;
}

.course-instructor {
	margin-right: 0.5rem;
	font-size: var(--text-base);
}

@media (max-width: 400px) {
	.course-instructor {
		margin-right: 0;
	}
}

.course-student-count {
	display: flex;
	font-size: var(--text-base);
	float: left;
	color: var(--gray-900);
}

.course-card-footer {
	display: flex;
	justify-content: space-between;
	margin-top: auto;
}

.course-price {
	font-weight: 500;
}

.view-course-link {
	height: 32px;
	border-radius: var(--border-radius-sm);
	font-size: 12px;
	padding: 8px 0px 8px;
	text-align: center;
	line-height: 135%;
	cursor: pointer;
}

.cards-parent {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 2rem;
	align-items: center;
}

@media (max-width: 767px) {
	.cards-parent {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		-moz-column-gap: 16px;
		column-gap: 16px;
		row-gap: 16px;
	}
}

@media (max-width: 375px) {
	.cards-parent {
		grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
		-moz-column-gap: 24px;
		column-gap: 24px;
		row-gap: 24px;
	}
}

@media (min-width: 576px) and (max-width: 992px) {
	.container {
		padding-right: 1rem;
		padding-left: 1rem;
	}
}

.button-links {
	color: inherit;
}

.button-links:hover {
	text-decoration: none;
	color: inherit;
}

.icon-background {
	border-radius: 50%;
	padding: 3px;
	width: 24px;
	height: 24px;
}

.review-card {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.review-content {
	margin: 1rem 3.5rem 0 0;
	color: var(--gray-700);
}

.custom-checkbox > label > .empty-checkbox {
	height: 1.5rem;
	width: 1.5rem;
	border: 1px solid black;
	border-radius: var(--border-radius-md);
}

.empty-checkbox {
	margin-left: 0.5rem;
}

.custom-checkbox > label > input:checked + .empty-checkbox {
	background: url(/assets/lms/icons/tick.svg);
	background-repeat: no-repeat;
	background-position: center center;
}

.quiz-label {
}

.quiz-label p {
	display: inline;
}

.option-row {
	display: flex;
	align-items: center;
	flex: 1;
	margin-bottom: 0;
	padding: 0.75rem;
	border: 1px solid var(--gray-200);
	border-radius: var(--border-radius-lg);
	cursor: pointer;
	background-color: var(--gray-100);
}

.active-option .option-row {
	background-color: var(--blue-50);
	border: 1px solid var(--blue-500);
}

.course-card-wide {
	width: 50%;
	margin-bottom: 2rem;
}

@media (max-width: 1000px) {
	.course-card-wide {
		width: 75%;
		margin: 0 auto 2rem;
	}
}

@media (max-width: 768px) {
	.course-card-wide {
		width: 100%;
		margin: 0 0 2rem;
	}
}

.course-card-instructors {
	display: flex;
	align-items: center;
}

.course-card-wide-content {
	display: flex;
	flex-direction: column;
	flex: 2;
	justify-content: space-between;
}

.course-card-wide-title {
	font-weight: 700;
	font-size: var(--text-4xl);
	line-height: 44px;
	color: var(--gray-900);
	margin: 0.25rem 0;
}

.wide-button {
	padding: 0.3rem 4rem;
	width: 100%;
}

@media (max-width: 768px) {
	.wide-button {
		padding: 0.3rem 4rem;
	}
}

.course-home-page .course-home-outline {
	padding-bottom: 4rem;
}

.course-home-page {
	background-color: #ffffff;
	padding-top: 2.5rem;
}

.chapter-title {
	cursor: pointer;
	border-radius: var(--border-radius-lg);
	color: var(--gray-900);
	display: flex;
	align-items: center;
	padding-bottom: 0.25rem;
	padding-left: 0.5rem;
	font-size: var(--text-lg);
}

.chapter-title:last-child {
	padding-bottom: 0;
}

.chapter-description {
	color: var(--gray-900);
	font-size: var(--text-sm);
	margin-right: 2rem;
	margin-bottom: 0.5rem;
}

.course-content-parent .chapter-description {
	font-size: 0.75rem;
}

.chapter-icon {
	margin-left: 0.5rem;
}

.reviews-parent {
	color: var(--gray-900);
}

.lesson-info {
	padding: 0.5rem;
	color: var(--gray-900);
	letter-spacing: -0.011em;
}

.lesson-links {
	display: flex;
	align-items: center;
	color: var(--gray-900);
	font-size: var(--text-base);
}

.lesson-links:hover {
	cursor: pointer;
	text-decoration: none;
	color: var(--gray-900);
	border-radius: var(--border-radius-md);
}

.member-card {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	font-size: var(--text-base);
}

.break {
	flex-basis: 100%;
	flex-grow: 1;
	margin: 0.5rem 0;
}

.course-home-headings {
	font-weight: 600;
	font-size: var(--text-3-5xl);
	letter-spacing: -0.0175em;
	color: var(--gray-900);
	margin-bottom: 1rem;
}

.course-home-headings:hover {
	color: var(--gray-900);
	text-decoration: none;
}

.modal-headings {
	margin: 0;
}

.avatar-square {
	width: 130px;
	height: 130px;
}

.avatar-square .avatar-frame {
	border-radius: 20px;
}

.avatar-medium {
	width: 42px;
	height: 42px;
}

.avatar-large {
	width: 68px;
	height: 68px;
}

.avatar-xl {
	width: 8rem;
	height: 8rem;
}

.avatar-xl .standard-image {
	border: 4px solid #ffffff;
}

@media (max-width: 500px) {
	.avatar-square {
		width: 75px;
		height: 75px;
	}
}

.member-parent {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	-moz-column-gap: 2rem;
	column-gap: 2rem;
	row-gap: 2rem;
}

@media (max-width: 600px) {
	.member-parent {
		grid-template-columns: repeat(auto-fill, minmax(125px, 1fr));
		-moz-column-gap: 2rem;
		column-gap: 2rem;
		row-gap: 2rem;
	}
}

.view-all-mentors {
	width: 100%;
	display: flex;
	align-items: baseline;
	cursor: pointer;
	margin-top: 1rem;
}

.review-modal .modal-dialog {
	width: 50%;
	height: 70%;
}

@media (max-width: 600px) {
	.review-modl .modal-dialog {
		width: auto;
	}
}

.error-field {
	color: red;
}

.quiz-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 2rem;
}

.question {
	flex-direction: column;
}

.question p {
	margin-bottom: 0;
}

.active-question .card-divider {
	margin-top: 1rem;
}

.dark-links {
	color: inherit;
}

.dark-links:hover {
	color: inherit;
}

.breadcrumb {
	display: flex;
	align-items: center;
	font-size: var(--text-base);
	line-height: 20px;
	color: var(--gray-900);
	padding: 0;
	border-radius: 0;
}

.course-details-outline {
	margin-top: 2.5rem;
}

.lesson-content {
	padding: 1.5rem;
	flex-direction: column;
}

.lesson-content-card {
	margin-top: 1.5rem;
}

.lesson-content-card .alert-dismissible .close {
	padding: 0.5rem;
}

.course-content-parent {
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: 1fr 3fr;
}

@media (max-width: 1024px) {
	.course-content-parent {
		display: flex;
		flex-direction: column-reverse;
	}
}

.course-content-parent .course-home-headings {
	margin: 0 0 0.5rem;
	width: 100%;
}

.lesson-pagination {
	margin: 2rem 0 5rem;
}

.lesson-video {
	width: 100%;
}

.active-lesson {
	background-color: var(--gray-200);
	border-radius: var(--border-radius-md);
}

.profile-page {
	padding-top: 0;
}

.profile-banner {
	height: 248px;
	background-size: cover;
	background-position: center;
}

@media (max-width: 500px) {
	.profile-banner {
		height: 150px;
	}
}

.profile-info {
	height: 90px;
	background: #ffffff;
	border-radius: 0px 0px 8px 8px;
	font-size: var(--text-sm);
	display: flex;
	flex-direction: column;
	margin-bottom: 2.5rem;
	padding-right: 200px;
	padding-left: 1rem;
	border: 1px solid var(--gray-300);
}

@media (max-width: 550px) {
	.profile-info {
		align-items: flex-end;
		padding-right: 0;
		height: 150px;
	}
}

.profile-avatar {
	position: relative;
	top: 188px;
	right: 40px;
	width: -moz-fit-content;
	width: fit-content;
}

@media (max-width: 500px) {
	.profile-avatar {
		top: 95px;
		right: 10px;
	}
}

@media (max-width: 375px) {
	.profile-avatar {
		top: 120px;
		right: 10px;
	}
}

.profile-name {
	color: var(--gray-900);
	font-weight: 600;
	font-size: var(--text-3xl);
}

@media (max-width: 375px) {
	.profile-name {
		font-size: var(--text-lg);
		padding-top: 5px;
	}
}

.profile-name-section {
	display: flex;
	align-items: center;
	margin: 0.5rem 0 0.25rem;
}

@media (max-width: 550px) {
	.profile-name-section {
		flex-direction: column;
		align-items: flex-end;
		margin: 0 0 0.5rem;
	}
}

.creator-badge {
	background: #48bb74;
	padding: 4px 6px;
	color: #ffffff;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	line-height: 120%;
	text-align: center;
	letter-spacing: 0.011em;
	text-transform: uppercase;
	height: -moz-fit-content;
	height: fit-content;
	box-shadow: 0px 1px 1px rgb(0 0 0 / 16%);
	border-radius: var(--border-radius-sm);
	margin-right: 0.5rem;
}

@media (max-width: 375px) {
	.creator-badge {
		font-size: 8px;
	}
}

@media (max-width: 550px) {
	.creator-badge {
		margin-top: 0.25rem;
		margin-right: 0;
	}
}

.profile-grid-card {
	display: grid;
	row-gap: 1rem;
}

.profile-item {
	color: var(--gray-800);
	font-weight: 500;
}

.education-details {
	margin-top: 1.25rem;
}

.bold-title {
	font-weight: 500;
	color: var(--gray-800);
}

.profile-courses {
	margin-top: 3rem;
}

.zindex {
	position: relative;
	z-index: 2;
}

.progress {
	width: 100%;
	height: 4px;
}

.course-card-wide .progress {
	background-color: var(--gray-500);
}

.progress-bar {
	background-color: var(--primary-color);
}

.progress-percent {
	margin: 0.5rem 0;
	font-size: var(--text-sm);
}

pre {
	width: 100%;
	overflow-x: auto;
}

.markdown-source h1 {
	font-size: 1.3rem;
}

.markdown-source h2 {
	font-size: 1.2rem;
}

.markdown-source h3 {
	font-size: 1.1rem;
}

.markdown-source h4 {
	font-size: 1rem;
}

.markdown-source p:last-child {
	margin-bottom: 0;
}

.avatar img {
	-o-object-fit: cover;
	   object-fit: cover;
}

.certificate-card {
	background: #ffffff;
	font-size: 14px;
}

.certificate-content {
	border-radius: 0.5rem;
	border: 1px solid #eef0f2;
	padding: 1rem;
	font-size: var(--text-lg);
}

.certificate-border {
	border: 10px solid #0089ff;
	/* border-image: url(/assets/lms/images/border.png);
	border-width: 100;
	border-style: solid ; */
	border-radius: 8px;
	padding: 6rem 4rem;
	background-color: #ffffff;
	text-align: center;
}

.certificate-parent {
	display: grid;
	grid-template-columns: 10fr 2fr;
	grid-gap: 3rem;
}

.certificate-logo {
	height: 1.5rem;
	margin-bottom: 4rem;
}

.certificate-name {
	font-size: 2rem;
	font-weight: 500;
	color: #192734;
	margin-bottom: 0.25rem;
}

.certificate-footer {
	margin: 4rem auto 0;
	width: -moz-fit-content;
	width: fit-content;
}

.certificate-footer-item {
	color: #192734;
}

.cursive-font {
	font-family: cursive;
	font-weight: 600;
}

.certificate-divider {
	margin: 0.5rem 0;
}

.certificate-expiry {
	margin-right: 2rem;
}

.column-card {
	flex-direction: column;
	padding: 1rem;
	height: 100%;
}

.empty-state {
	border: 1px solid var(--gray-300);
	border-radius: var(--border-radius-lg);
	padding: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
}

.empty-state-text {
	margin-right: 1rem;
}

.empty-state-heading {
	font-size: var(--text-xl);
	color: var(--text-color);
	font-weight: 600;
}

.vertically-center {
	display: flex;
	align-items: center;
}

.search {
	background-image: url(/assets/frappe/icons/timeless/search.svg);
	border: none;
	border-radius: var(--border-radius-md);
	font-size: var(--text-sm);
	padding: 0.625rem 0.75rem;
	height: 36px;
	background-repeat: no-repeat;
	text-indent: 1.5rem;
	background-position: right 1rem top 0.7rem;
	width: 30%;
	box-shadow: var(--shadow-sm);
}

.search-course {
	background-position: right 1rem top 50%;
	text-indent: 1rem;
	font-size: var(--text-base);
	padding: 1.5rem;
	width: 100%;
	box-shadow: none;
}

.w-full {
	width: 100%;
}

.h-full {
	height: 100%;
}

.search:focus {
	outline: none;
}

.course-search-header {
	float: left;
	width: 80%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

@media (max-width: 1250px) {
	.search {
		width: 40%;
	}
}

@media (max-width: 1000px) {
	.search {
		width: 55%;
	}

	.course-search-header {
		width: 75%;
	}
}

@media (max-width: 650px) {
	.course-search-header {
		width: 60%;
	}
}

@media (max-width: 550px) {
	.course-search-header {
		float: none;
		width: 100%;
		justify-content: space-between;
		margin-bottom: 1rem;
	}
}

.testimonial-card {
	flex-direction: column;
	padding: 2rem;
	height: 100%;
}

.testimonial-author-name {
	font-weight: 500;
	font-size: var(--text-lg);
	color: var(--text-color);
}

.testimonial-review {
	color: var(--text-color);
	margin-bottom: 2rem;
}

.testimonial-footer {
	display: flex;
	align-items: center;
	border-top: 1px solid var(--gray-200);
	padding-top: 2rem;
	margin-top: auto;
}

.testimonial-profession {
	font-size: 0.625rem;
}

.carousel-indicators li {
	background-color: var(--gray-600);
	width: 10px;
	height: 10px;
	border-radius: 50%;
}

.carousel-control-prev-icon {
	background-image: url("/assets/lms/icons/slider-arrow-left.svg");
	width: 40px;
	height: 40px;
}

.carousel-control-next-icon {
	background-image: url("/assets/lms/icons/slider-arrow-right.svg");
	width: 40px;
	height: 40px;
}

.carousel-control-prev,
.carousel-control-next {
	width: auto;
	top: 40%;
	height: -moz-fit-content;
	height: fit-content;
	background: white;
	border-radius: 50%;
	box-shadow: var(--shadow-sm);
	opacity: 1;
}

.related-courses .carousel-control-prev,
.related-courses .carousel-control-next {
	top: 40%;
}

.related-courses {
	background: var(--gray-50);
	padding: 5rem 0;
	position: relative;
	z-index: 5;
}

.carousel-indicators {
	position: inherit;
	margin: 0;
}

.carousel {
	padding: 0 1.5rem;
}

@media (max-width: 500px) {
	.carousel {
		padding: 0 0.5rem;
	}
}

.slider-controls {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin: 40px auto 0;
}

.carousel-indicators li {
	border: none;
	margin: 0 6px;
}

.search-empty-state {
	position: relative;
	top: 1rem;
	margin-bottom: 5rem;
	width: 100%;
}

.close-search-empty-state {
	position: absolute;
	top: 0;
	left: 0;
	padding: 0.75rem 1.25rem;
	float: left;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
}

.close-search-empty-state:hover {
	text-decoration: none;
	color: #000;
}

.live-courses .course-home-headings {
	margin-bottom: 1.5rem;
	width: -moz-fit-content;
	width: fit-content;
}

@media (min-width: 768px) {
	.lesson-pagination .custom-checkbox .empty-checkbox {
		width: 1rem;
		height: 1rem;
		border-radius: var(--border-radius-sm);
	}
}

@media (max-width: 767px) {
	.lesson-pagination .custom-checkbox .empty-checkbox {
		margin-bottom: 1rem;
		border-radius: var(--border-radius-sm);
	}

	.lesson-pagination .custom-checkbox span {
		display: inline-block;
		width: 70%;
		font-size: 10px;
	}
}

.lesson-pagination .custom-checkbox input:checked + .empty-checkbox {
	background-size: 1rem;
}

.no-discussions {
	width: 80% !important;
}

.preview-work {
	display: flex;
	align-items: center;
}

.job-card {
	position: relative;
	padding: 1rem;
}

.company-logo {
	background-position: center;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	border-radius: var(--border-radius-sm);
	width: 50px;
	height: 50px;
	margin-left: 1rem;
}

.job-card-parent {
	display: grid;
	row-gap: 1rem;
}

.job-card-logo-section {
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
}

.job-detail-card {
	padding: 1rem;
	flex-direction: column;
}

.job-subtitle {
	font-size: var(--text-base);
	margin-bottom: 1.875rem;
}

.card-heading {
	font-weight: 600;
	color: var(--gray-900);
	margin-bottom: 0.25rem;
}

.course-head-container {
	border-bottom: 1px solid var(--gray-300);
}

.seperator {
	margin: 0 1rem;
}

.seperator::after {
	content: "\00B7";
}

.course-overlay-card {
	background-color: white;
	border-radius: var(--border-radius-lg);
	border: 1px solid var(--gray-300);
	overflow: auto;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	top: 10%;
	left: 7%;
	width: 350px;
	z-index: 4;
}

@media (max-width: 1000px) {
	.course-overlay-card {
		position: inherit;
		margin: 1rem auto;
	}

	.seperator {
		margin: 0 0.25rem;
	}
}

.course-overlay-content {
	padding: 1.25rem;
	font-size: var(--text-base);
	color: var(--text-color);
}

.breadcrumb-destination {
	color: var(--gray-600);
}

.preview-video {
	width: 100%;
	height: 190px;
	border: none;
}

.course-body-container {
	width: 60%;
}

@media (max-width: 1000px) {
	.course-body-container {
		width: 100%;
	}
}

.overlay-heading {
	margin-top: 2rem;
	font-weight: 600;
	color: var(--gray-800);
}

.overlay-student-count {
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
}

.course-creators-card {
	display: grid;
	grid-gap: 1rem;
	padding: 1rem;
	background-color: var(--gray-100);
	box-shadow: none;
}

.course-meta {
	font-size: var(--text-base);
	color: var(--gray-900);
}

.avg-rating {
	font-size: var(--text-3-8xl);
	color: var(--gray-900);
	font-weight: bold;
}

.reviews-header {
	display: flex;
	justify-content: space-between;
	width: 75%;
}

@media (max-width: 1250px) {
	.reviews-header {
		width: 85%;
	}
}

@media (max-width: 768px) {
	.reviews-header {
		width: 100%;
	}
}

@media (max-width: 500px) {
	.reviews-header {
		flex-direction: column;
		align-items: center;
	}

	.vertical-divider {
		margin: 1rem;
	}
}

.bold-heading {
	font-size: var(--text-lg);
	color: var(--gray-900);
	font-weight: 600;
}

.star-click {
	--star-fill: var(--yellow-500);
	margin-left: 0;
}

.rating {
	--star-fill: var(--gray-400);
}

.vertical-divider {
	border: 1px solid var(--gray-300);
	margin: 0 1rem;
}

.course-card-wide .avg-rating-stars {
	margin-top: 2rem;
}

.reviews-parent .progress {
	width: 200px;
	color: var(--gray-900);
}

.reviews-parent .progress-bar {
	background-color: var(--primary-color);
}

.course-home-top-container {
	position: relative;
}

.question-text {
	margin: 0.5rem 0 1rem;
	font-weight: 600;
}

.question-text .ql-editor.read-mode {
	white-space: inherit;
	font-weight: 600;
}

.profile-column-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}

.description {
	font-size: var(--text-base);
	color: var(--gray-800);
}

.profile-meta {
	display: flex;
	align-items: center;
}

.carousel-inner {
	overflow: inherit;
}

.lms-nav .nav-link {
	padding: var(--padding-sm) 0;
	margin: 0 var(--margin-md);
	font-size: var(--text-base);
	color: var(--text-muted);
}

.lms-nav .nav-link.active {
	font-weight: 500;
	border-bottom: 1px solid var(--primary-color);
	color: var(--primary-color);
}

@media (min-width: 500px) {
	.lms-nav .nav-item:first-child .nav-link {
		margin-right: 0;
	}
}

.dashboard-button {
	position: relative;
	top: -50px;
	margin-right: auto;
}

.course-card-wide .breadcrumb {
	margin-bottom: 0;
}

.course-content-parent .course-details-outline .course-home-headings {
	display: none;
}

.btn-outline-primary {
	border: 1px solid var(--primary-color);
}

.show-attachments {
	padding-left: 0.5rem;
	display: flex;
	align-items: center;
}

.attachment-controls {
	display: flex;
	align-items: center;
	width: -moz-fit-content;
	width: fit-content;
	cursor: pointer;
}

.attachments {
	flex-direction: column;
	padding: 0.5rem 0;
	margin-top: 1rem;
	position: absolute;
	z-index: 1;
	width: -moz-fit-content;
	width: fit-content;
	border-collapse: separate;
	border-spacing: 1rem 0.5rem;
}

li {
	line-height: 1.7;
}

.course-overlay-title {
	font-weight: 700;
	font-size: var(--text-2xl);
	line-height: 1.5;
	color: var(--gray-900);
	margin-bottom: 1.25rem;
}

.no-preview {
	color: var(--gray-600);
}

.job-cards-parent {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
	-moz-column-gap: 40px;
	grid-gap: 1rem;
	align-items: center;
}

.job-company {
	display: flex;
	align-items: center;
}

.job-actions {
	display: flex;
	align-items: flex-start;
	margin-right: auto;
	margin-bottom: 1rem;
}

.job-detail-header {
	display: flex;
}

@media (max-width: 600px) {
	.job-company {
		flex-direction: column;
		align-items: inherit;
	}
}

@media (max-width: 1200px) {
	.job-cards-parent {
		grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
	}
}

@media (max-width: 500px) {
	.job-cards-parent {
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	}

	.job-detail-header {
		flex-wrap: wrap;
	}

	.job-actions {
		margin-top: 1rem;
	}
}

.course-image-attachment {
	margin-top: 0.25rem;
	background-color: var(--bg-light-gray);
	border-radius: var(--border-radius);
	border: 1px solid var(--gray-300);
	padding: 0.5rem 0.75rem;
	width: -moz-fit-content;
	width: fit-content;
}

.btn-delete-tag {
	cursor: pointer;
}

.chapter-edit {
	border: 1px solid var(--dark-border-color);
	border-radius: var(--border-radius);
	padding: 1rem;
	margin-bottom: 1rem;
}

.chapter-edit .lessons {
	margin-right: 0;
}

.chapter-parent {
	margin-bottom: 1rem;
}

.chapter-parent:last-child {
	margin-bottom: 0;
}

.chapter-edit .chapter-title {
	padding: 0.5rem 0;
}

.preview {
	display: flex;
	align-items: center;
	font-size: var(--text-md);
}

.table {
	margin-bottom: 0;
}

.option-input {
	width: 45%;
	margin-left: 1rem;
}

.option-checkbox {
	width: 15%;
	display: flex;
	align-items: center;
}

.preview-video-header {
	position: relative;
}

.preview-info {
	position: absolute;
	top: 0;
	left: -30px;
}

.tool-tip {
	position: relative;
	display: inline-block;
}

.tool-tip .tooltiptext {
	visibility: hidden;
	width: 30rem;
	background-color: var(--gray-800);
	color: var(--fg-color);
	padding: 1rem;
	border-radius: var(--border-radius-md);
	position: absolute;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;
}

.tool-tip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}

.tooltiptext ul {
	padding: 1rem;
}

.medium {
	font-size: var(--text-base);
}

.quiz-row {
	position: relative;
	color: var(--text-color);
	cursor: pointer;
}

.course-creation-link {
	float: left;
}

@media (max-width: 500px) {
	.course-creation-link {
		float: inherit;
		margin-bottom: 1rem;
	}
}

.indicator-pill::before {
	width: 0 !important;
	height: 0 !important;
	margin-left: 0 !important;
}

.role {
	display: flex;
	align-items: center;
	margin-bottom: 0;
	cursor: pointer;
}

@media (min-width: 500px) {
	.role:last-child {
		margin-right: 5rem;
	}
}

.icon-xl {
	width: 2.75rem;
	height: 2.75rem;
}

.modal .comment-field {
	height: 150px !important;
	resize: auto !important;
}

.notification-card {
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	position: relative;
}

.notification-card:last-child {
	margin-bottom: 0;
}

.timestamp {
	font-size: var(--text-xs);
}

.stats-parent {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 2rem;
}

.statistics .stats-parent {
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	grid-gap: 1rem;
}

.stats-label {
	color: var(--gray-900);
	font-weight: 500;
}

.stats-value {
	color: var(--gray-900);
	font-weight: 500;
	font-size: 1.5rem;
	margin-top: 2rem;
}

.indicator-pill.green::before {
	height: 0;
	width: 0;
	border-radius: 0;
	margin-left: 0;
}

.modal-header {
	padding: 1rem 1rem 0 !important;
}

.modal-body {
	padding: 0 1rem !important;
}

.modal-dialog-scrollable .modal-content {
	overflow: unset;
}

.modal-header,
.modal-body {
	margin-bottom: 0.5rem !important;
}

.modal-header {
	border-bottom: none !important;
}

.modal-footer {
	padding: 0.75rem 1.5rem !important;
	justify-content: flex-end !important;
}

.modal-footer .btn-primary {
	margin-right: 0.5rem;
}

.modal-header .modal-title {
	color: var(--gray-900);
	line-height: 1.5rem;
	margin-bottom: 0.5rem;
}

.frappe-chart .title {
	font-size: 1rem;
	font-weight: 500;
	fill: var(--gray-900);
}

.course-description-section {
	padding-bottom: 2.5rem;
}

input::file-selector-button {
	border-radius: var(--border-radius);
	font-size: var(--text-md);
	padding: 0.25rem 1.25rem;
	border: none;
	color: var(--text-color);
	cursor: pointer;
}

input::file-selector-button:hover {
	background-color: var(--gray-400);
}

.btn {
	font-weight: 400;
}

select {
	-moz-appearance: none;
	     appearance: none;
	-webkit-appearance: none;
}

.modal-title {
	font-size: var(--text-lg) !important;
}

.class-form-title {
	font-size: var(--text-base);
}

.remove-student {
	cursor: pointer;
}

.class-course-list {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
	grid-gap: 1rem;
	font-size: var(--text-base);
}

.class-cours {
	cursor: pointer;
}

.subheading {
	font-weight: 500;
	color: var(--gray-900);
}

.progress-course-header {
	display: flex;
	background-color: var(--gray-100);
	padding: 0.5rem;
}

.section-heading {
	font-size: 1rem;
	color: var(--gray-900);
	font-weight: 500;
}

.table th {
	font-weight: 400;
	border: none;
	font-size: var(--text-md);
}

.table td {
	border: none;
}

.table tr:first-child,
.table tr:last-child {
	border-bottom: none;
}

.table tr {
	border-bottom: 1px solid var(--gray-300);
}

.lms-dropdown {
	border: 1px solid var(--gray-400);
	border-radius: var(--border-radius-sm);
	padding: 0.25rem 2rem;
	cursor: pointer;
	text-align: center;
}

.lms-menu {
	background-color: var(--control-bg);
	color: var(--text-color);
	border: none;
	border-radius: var(--border-radius);
	padding: 0.15rem 0.5rem;
	box-shadow: var(--btn-shadow);
	background-image: url(/assets/lms/icons/down-arrow.svg);
	background-position: left 0.5rem center;
	background-repeat: no-repeat;
	background-size: 0.75rem;
	padding-left: 2rem;
	-webkit-print-color-adjust: exact;
}

.lms-menu:focus-visible {
	outline: var(--gray-500);
}

.clickable-row {
	cursor: pointer;
}

.onboarding-parent {
	background-color: var(--gray-100);
	padding: 1rem 0;
}

.onboarding-steps {
	display: flex;
	justify-content: space-between;
	padding-top: 1.5rem;
	font-weight: 500;
	color: var(--gray-900);
}

.onboarding-steps-link {
	display: flex;
	align-items: center;
	color: inherit;
}

.onboarding-steps-link:hover {
	text-decoration: none;
	color: inherit;
}

.onboarding-skip {
	font-size: var(--text-sm);
	float: left;
	cursor: pointer;
	margin-left: 1rem;
}

.result-row {
	display: block;
	padding: 1rem;
	border-top: 1px solid var(--gray-300);
	font-weight: 500;
	color: var(--gray-900);
	font-size: var(--text-base);
	cursor: pointer;
}

.result-row:hover {
	color: inherit;
	text-decoration: none;
}

.search-modal .modal-dialog {
	max-width: 70%;
	margin: 15% auto !important;
}

.search-modal .modal-body {
	padding: 0 !important;
	margin: 0 !important;
}

.member-card-title {
	color: var(--gray-900);
	font-weight: 500;
}

.tooltip-content {
	display: none;
}

.resize-none {
	resize: none;
}

.lms-page-style {
	background-color: var(--fg-color);
	font-size: var(--text-base);
}

.lms-card {
	display: flex;
	flex-direction: column;
	border-radius: 0.75rem;
	border: 1px solid var(--gray-300);
	/* box-shadow: var(--shadow-sm); */
	padding: 0.5rem;
	height: 100%;
	position: relative;
}

.live-class-panel {
	margin-top: auto;
}

.lms-card .live-class-panel .btn {
	visibility: hidden;
}

.lms-card:hover .live-class-panel .btn {
	visibility: visible;
}

.add-students ul li:nth-last-child(-n + 2) {
	display: none;
}

.lms-card-title {
	color: var(--gray-900);
	font-weight: 500;
}

.lms-card-parent {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	grid-gap: 1.5rem;
}

.answer-indicator {
	border-radius: var(--border-radius-md);
	padding: 0.2rem 0.5rem;
	width: -moz-fit-content;
	width: fit-content;
	margin-top: 0.5rem;
}

.answer-indicator.success {
	background-color: var(--dark-green-50);
}

.answer-indicator.failure {
	background-color: var(--red-50);
}

.list-row {
	border-bottom: 1px solid var(--gray-300);
	padding: 0.75rem 0;
}

.list-row:last-child {
	border-bottom: none;
}

.students-parent {
	display: grid;
	grid-template-columns: repeat(auto-fill, 150px);
	grid-gap: 1rem;
}

.rows .grid-row .data-row,
.rows .grid-row .grid-footer-toolbar,
.grid-form-heading {
	cursor: none;
}

.schedule-header {
	display: flex;
	font-size: var(--text-sm);
	padding: 0.5rem 0.5rem 0 0.5rem;
}

.lms-page-style .discussions-section-title {
	font-size: var(--text-lg);
}

.class-dashboard .progress {
	width: 150px;
	height: 150px;
	background: none;
	position: relative;
}

.class-dashboard .progress::after {
	content: "";
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 6px solid #eee;
	position: absolute;
	top: 0;
	right: 0;
}

.class-dashboard .progress > span {
	width: 50%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: 1;
}

.class-dashboard .progress .progress-left {
	right: 0;
}

.class-dashboard .progress .progress-bar {
	width: 100%;
	height: 100%;
	background: none;
	border-width: 6px;
	border-style: solid;
	position: absolute;
	top: 0;
}

.class-dashboard .progress .progress-left .progress-bar {
	right: 100%;
	border-top-left-radius: 80px;
	border-bottom-left-radius: 80px;
	border-right: 0;
	transform-origin: center right;
}

.class-dashboard .progress .progress-right {
	left: 0;
}

.class-dashboard .progress .progress-right .progress-bar {
	right: -100%;
	border-top-right-radius: 80px;
	border-bottom-right-radius: 80px;
	border-left: 0;
	transform-origin: center left;
}

.class-dashboard .progress .progress-value {
	position: absolute;
	top: 0;
	right: 0;
}

.form-section .section-head {
	margin-bottom: var(--margin-sm);
	font-weight: 700;
	color: var(--heading-color);
}

.form-column:first-child {
	padding-left: 1rem !important;
}

.class-overlay {
	top: 30%;
}

.course-list-menu {
	display: flex;
	align-items: center;
	float: left;
}

.course-list-buttons {
	display: flex;
}

.course-list-buttons .btn {
	margin-right: 0.5rem;
}

@media (max-width: 767px) {
	.course-list-menu {
		float: none;
		margin-bottom: 1rem;
	}
}

@media (max-width: 576px) {
	.course-list-menu {
		flex-direction: column;
		align-items: unset;
	}

	.course-list-buttons {
		margin-top: 1rem;
		justify-content: space-between;
	}

	.course-list-buttons .btn {
		margin-right: 0;
	}
}

.slots-parent {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 0.5rem;
	margin-bottom: 1rem;
}

.batch-course-list .cards-parent {
	row-gap: 3rem;
}

.embed-tool__caption {
	display: none;
}

.card-buttons {
	display: flex;
	position: relative;
	top: 10%;
	right: 80%;
	z-index: 10;
	width: -moz-fit-content;
	width: fit-content;
}

.toastui-calendar-milestone {
	display: none;
}

.toastui-calendar-task {
	display: none;
}

.toastui-calendar-panel-resizer {
	display: none;
}

.toastui-calendar-day-name__date {
	font-size: var(--text-base) !important;
}

.toastui-calendar-day-name__name {
	font-size: var(--text-base) !important;
}

.toastui-calendar-day-view-day-names,
.toastui-calendar-week-view-day-names {
	border-bottom: none !important;
}

.toastui-calendar-layout {
	border: 1px solid var(--gray-200) !important;
	border-radius: var(--border-radius-md) !important;
	background-color: var(--gray-100) !important;
}

.toastui-calendar-panel .toastui-calendar-day-names.toastui-calendar-week {
	border-top: none !important;
}

.toastui-calendar-panel.toastui-calendar-time {
	height: 80% !important;
}

.toastui-calendar-panel.toastui-calendar-week-view-day-names {
	background-color: var(--gray-50) !important;
}

.toastui-calendar-allday {
	border-bottom: 1px solid var(--gray-200) !important;
}

.calendar-navigation {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
}

.calendar-range {
	margin: 0 2rem;
	font-weight: 500;
	color: var(--text-color);
}

.calendar-event-title {
	font-size: var(--text-md);
	font-weight: 500;
	margin-top: 0.2rem;
}

.legend-color {
	width: 50px;
	height: 20px;
	border-radius: var(--border-radius-sm);
	margin-left: 0.25rem;
}

.legend-item {
	display: flex;
	align-items: center;
}

.legend-text {
	color: var(--text-color);
	font-weight: 500;
}

.calendar-legends {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 50%;
	margin: 0 auto 1rem;
}

.batch-details {
	width: 50%;
	margin: 2rem 0;
}

@media (max-width: 1000px) {
	.batch-details {
		width: 100%;
	}
}

.collapse-section {
	font-size: var(--text-lg);
	cursor: pointer;
}

.collapse-section.collapsed .icon {
	transition: all 0.5s;
	transform: rotate(-180deg);
}

.modal-body .ql-container {
	max-height: unset !important;
}

.questions-table .row-index {
	display: none;
}

.text-color {
	color: var(--text-color);
}

/* Kanban */

/* The sidebar */
.task-sidebar {
	height: 100%;
	width: 0;
	position: fixed;
	z-index: 1000;
	top: 0;
	left: 0;
	background-color: white;
	overflow-x: hidden;
	transition: 0.5s;
	box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
	flex-direction: column;
	display: flex;
}

/* Sidebar content */
.task-sidebar-content {
	padding: 20px;
}

/* Close button */
.close-btn {
	font-size: 30px;
	background: none;
	border: none;
	cursor: pointer;
	position: absolute;
	top: 10px;
	left: 20px;
}

/* Slide in effect */
.task-sidebar.open {
	width: 500px;
}
